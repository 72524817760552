import React, { FC } from 'react';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FadeIn from 'framers/FadeIn';
import Multiselect from 'multiselect-react-dropdown';

import { Container } from 'layout';
import Button from 'components/Button';

import { useScrollTo } from 'hooks/useScrollTo';

import { AnchorsProps } from './models.d';

import './Anchors.scss';

const Anchors: FC<AnchorsProps> = ({ className, anchors, scrollParams }) => {
  const anchorsClasses = classNames('anchors', className);
  const anchorsUlClasses = classNames('anchors__ul', 'hide--on-mobile');
  const anchorsSelectClasses = classNames('anchors__select', 'hide--on-desktop');

  const [handleScrollTo, activeElement] = useScrollTo(scrollParams);

  const onSelect = (_, { queryString }) => {
    handleScrollTo(queryString);
  };

  return (
    <>
      {anchors ? (
        <div className={anchorsClasses} data-testid="anchors-item">
          <Container gutter>
            <FadeIn>
              <Multiselect
                options={anchors}
                displayValue="name"
                showArrow
                className={anchorsSelectClasses}
                closeOnSelect
                singleSelect
                onSelect={onSelect}
                customArrow={<FontAwesomeIcon icon={faAngleDown} size="1x" />}
              />
              <ul className={anchorsUlClasses}>
                {anchors.map(({ name, queryString }) => (
                  <li
                    key={name}
                    className={classNames('anchors__item', {
                      'anchors__item--active': queryString === activeElement,
                    })}
                  >
                    <Button
                      ui={{
                        variant: 'simple',
                        typography: { size: 20, color: 'sherwood-green', weight: 'bold' },
                      }}
                      className="background--transparent"
                      onClick={() => handleScrollTo(queryString)}
                    >
                      {name}
                    </Button>
                  </li>
                ))}
              </ul>
            </FadeIn>
          </Container>
        </div>
      ) : null}
    </>
  );
};

export default Anchors;
