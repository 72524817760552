/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import ContentElement from 'components/ContentElement';
import { SliderVariant } from 'components/Slider';
import Anchors from 'containers/Anchors';
import Carousel from 'containers/Carousel';
import TreatmentSection from 'containers/TreatmentSection';

import sortArr from 'utils/sortArr';
import { DEFAULT_SCROLL_PARAMS } from 'hooks/useScrollTo/constans';

const AilmentsPage: FC<AilmentsPageTypes.AilmentsProps> = ({
  data: {
    ailments: {
      urls,
      seo,
      banner,
      contentElement,
      treatmentSection,
      articleCarousel,
      productCarousel,
      contentSection,
      anchors,
    },
    allProductDetail: { productsList },
    allArticleDetail: { articlesList },
  },
}) => {
  const { title, keywords, description } = seo;

  const { ARTICLES, PRODUCTS } = SliderVariant;

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner {...banner} />
      <Anchors anchors={anchors} scrollParams={{ ...DEFAULT_SCROLL_PARAMS, offset: -100 }} />
      <ContentElement contentElement={contentElement} showNav />
      {treatmentSection.map((section, idx) =>
        treatmentSection.length ? (
          <TreatmentSection
            key={`${section.disclaimer} + ${section.variant} + ${idx}`}
            treatmentSection={section}
          />
        ) : null
      )}
      <ContentElement contentElement={contentSection} />
      {articlesList.length && articleCarousel ? (
        <Carousel
          carouselItems={sortArr(articlesList, 'articleCardOrder')}
          carouselVariant={ARTICLES}
          carousel={articleCarousel}
          sliderId="articles"
        />
      ) : null}
      {productsList.length && productCarousel ? (
        <Carousel
          carouselItems={productsList}
          carouselVariant={PRODUCTS}
          carousel={productCarousel}
          sliderId="products"
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $path: [String], $productsUrls: [String], $articlesUrls: [String]) {
    ailments(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      banner {
        ...BannerFragment
      }
      contentElement {
        ...ContentElementFragment
      }
      treatmentSection {
        ...TreatmentSectionFragment
      }
      contentSection {
        ...ContentElementFragment
      }
      articleCarousel {
        ...CarouselFragment
      }
      productCarousel {
        ...CarouselFragment
      }
      anchors {
        ...AnchorsFragment
      }
    }
    allProductDetail(filter: { url: { in: $productsUrls } }) {
      productsList: nodes {
        ...ProductCardFragment
      }
    }
    allArticleDetail(filter: { url: { in: $articlesUrls } }) {
      articlesList: nodes {
        ...ArticleCardFragment
        articleCardOrder
      }
    }
  }
`;

export default AilmentsPage;
