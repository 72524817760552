import React from 'react';
import classNames from 'classnames';

import { Container } from 'layout';
import Typography from 'common/Typography';
import ContentElement from 'components/ContentElement';

import { TreatmentSectionProps } from './models.d';

import './TreatmentSection.scss';

const TreatmentSection = ({ className, treatmentSection }: TreatmentSectionProps) => {
  const { introduction, disclaimer, treatments, variant } = treatmentSection;
  const treatmentSectionClasses = classNames(
    'treatment-section ',
    {
      [`treatment-section--${variant}`]: variant,
    },
    className
  );

  return (
    <div className={treatmentSectionClasses} data-testid="treatment-section-item">
      <Container gutter>
        {introduction ? <ContentElement contentElement={introduction} /> : null}
        {treatments.length ? (
          <div className="treatment-section__treatments">
            <ContentElement contentElement={treatments} />
          </div>
        ) : null}
        {disclaimer ? (
          <Typography
            className="treatment-section__disclaimer color--grey"
            size={{ base: 16, md: 18 }}
            padding={{ left: 'xs' }}
            dangerouslySetInnerHTML={disclaimer}
          />
        ) : null}
      </Container>
    </div>
  );
};

export default TreatmentSection;
