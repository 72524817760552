import { useState } from 'react';
import scrollToElement from 'scroll-to-element';

import { DEFAULT_SCROLL_PARAMS } from './constans';

import { ScrollParamsType } from './models.d';

export const useScrollTo = (scrollParams: ScrollParamsType = DEFAULT_SCROLL_PARAMS) => {
  const [activeElement, setActiveElement] = useState<string>();

  const handleScrollTo = (queryString) => {
    scrollToElement(queryString, scrollParams);
    setActiveElement(queryString);
  };

  return [handleScrollTo, activeElement] as const;
};
