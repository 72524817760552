import { ImageType } from 'common/Image';
import { TypographyProps } from 'common/Typography';

export enum ContentElementVariant {
  TREATMENT = 'treatment',
  CAUSES = 'causes',
  MAIN = 'main',
}

export type ContentElementType = {
  title?: string;
  description?: string;
  image?: ImageType;
  variant?: ContentElementVariant;
  backgroundColor?: UiSettings.ColorPickerType;
  id?: string;
  imageIsInInvisible?: string;
};

export type ContentElementProps = {
  contentElement: ContentElementType[];
  className?: string;
  showNav?: boolean;
  navHeading?: string;
  firstHeadingH1?: boolean;
};

export type ContentElementMappedType = {
  [variant in ContentElementVariant]: TypographyProps;
};
