import { ImageStructureMock } from 'common/Image';

import { ContentElementVariant } from '../models.d';

export const ContentElementDefaultClassesMock = ['content-element', 'test-class'];

const { TREATMENT } = ContentElementVariant;

export const ContentElementMock = {
  description:
    "<p>As winter weather begins to creep in, so do germs. Did you know that these unwanted winter guests can sneak into your home unnoticed? It's like having an unwell family member you don't even know is there.</p>",
  title: 'content title',
  image: ImageStructureMock,
  variant: TREATMENT,
};

export const ContentElementDefaultPropsMock = {
  className: 'test-class',
  contentElement: [ContentElementMock],
};

export const ContentElementChildrenMock = 'test content';
