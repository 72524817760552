import { ContentElementMock } from 'components/ContentElement';

export const TreatmentSectionDefaultClassesMock = ['treatment-section', 'test-class'];

export const TreatmentSectionDefaultPropsMock = {
  className: 'test-class',
  treatmentSection: {
    introduction: [ContentElementMock],
    disclaimer:
      "<p>If changing your diet and lifestyle doesn't seem to help, you might need to <strong><u>try a laxative</u></strong> to help ease symptoms.</p>\n<p>Available over the counter from pharmacies and supermarkets and prescriptions from your GP.</p>",

    variant: 'constipation',
    treatments: [ContentElementMock],
  },
};

export const TreatmentSectionChildrenMock = 'test content';
