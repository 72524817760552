import React from 'react';
import classNames from 'classnames';
import FadeIn, { FADEIN } from 'framers/FadeIn';

import { Container } from 'layout';
import Image from 'common/Image';
import Typography from 'common/Typography';
import Button from 'components/Button';

import { useScrollTo } from 'hooks/useScrollTo';

import { ContentElementMappedType, ContentElementProps } from './models.d';

import './ContentElement.scss';

const { DOWN } = FADEIN;

const CONTENT_ELEMENT_CONSTANTS = {
  TREATMENT: 'treatment',
};

const ContentElement = ({
  className,
  contentElement,
  navHeading,
  showNav = false,
  firstHeadingH1,
}: ContentElementProps) => {
  const contentElementsWithId = contentElement ? contentElement.filter((el) => !!el.id) : null;

  const [handleScrollTo] = useScrollTo();

  return (
    <>
      {showNav && contentElementsWithId && navHeading ? (
        <div className="content-element__nav">
          <Container gutter>
            <Typography
              className="content-element__title"
              as="h2"
              color="sherwood-green"
              size={{ base: 25, md: 36 }}
              weight="bold"
              padding={{ top: 'md', bottom: 'md' }}
              animationVariant={DOWN}
              dangerouslySetInnerHTML={navHeading}
            />
            {contentElementsWithId.map((el, index) => {
              const queryString = `#${el.id}`;

              return (
                <FadeIn>
                  <Button
                    ui={{
                      variant: 'simple',
                      typography: {
                        size: 18,
                        lineHeight: 'big',
                        align: 'left',
                        color: 'sherwood-green',
                      },
                    }}
                    key={el.id}
                    onClick={() => handleScrollTo(queryString)}
                    className="content-element__nav-button background--transparent"
                  >
                    {`${index + 1}. ${el.title}`}
                  </Button>
                </FadeIn>
              );
            })}
          </Container>
        </div>
      ) : null}
      {contentElement
        ? contentElement.map(
            (
              { title, description, image, backgroundColor, variant, imageIsInInvisible, id },
              index
            ): JSX.Element => {
              const contentElementClasses = classNames(
                'content-element',
                {
                  [`content-element--${variant}`]: variant,
                  [`background--${backgroundColor}`]: backgroundColor,
                },
                className
              );

              const contentElementTypography: Partial<ContentElementMappedType> = {
                treatment: {
                  size: 20,
                },
                main: {
                  size: { base: 30, md: 40 },
                },
              };

              const titleSize = () => {
                if (variant && contentElementTypography[variant]) {
                  return contentElementTypography[variant]?.size;
                }

                return { base: 25, md: 36 };
              };

              return (
                <article
                  className={contentElementClasses}
                  data-testid="content-element-item"
                  id={id}
                  key={`${title}${description}`}
                >
                  <Container
                    gutter
                    className={classNames('content-element__wrapper', {
                      'content-element__wrapper--without-grid': !image,
                    })}
                  >
                    {title ? (
                      <Typography
                        className="content-element__title"
                        color="sherwood-green"
                        size={
                          title.toLowerCase().includes(CONTENT_ELEMENT_CONSTANTS.TREATMENT)
                            ? { base: 25, md: 36 }
                            : titleSize()
                        }
                        weight="bold"
                        as={firstHeadingH1 && index === 0 ? 'h1' : 'h2'}
                        padding={{ top: 'md', bottom: 'md' }}
                        animationVariant={DOWN}
                        dangerouslySetInnerHTML={title}
                      />
                    ) : null}
                    {description ? (
                      <Typography
                        className={classNames('content-element__description color--grey', {
                          'content-element__description--max-width': image,
                        })}
                        size={{ base: 16, md: 18 }}
                        dangerouslySetInnerHTML={description}
                        padding={{ bottom: 'md' }}
                        animationVariant={DOWN}
                      />
                    ) : null}
                    {image ? (
                      <Image
                        {...image.imageStructure}
                        className={classNames('content-element__image', {
                          'content-element__image--show': imageIsInInvisible,
                        })}
                        objectFit={imageIsInInvisible ? 'fill' : 'cover'}
                      />
                    ) : null}
                  </Container>
                </article>
              );
            }
          )
        : null}
    </>
  );
};
export default ContentElement;
